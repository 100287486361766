export default {
  page: {
    title: {
      base: 'Autopay gateway di pagamento',
      start: 'Scelta del metodo di pagamento',
      visadata: 'Visa Mobile',
      continue: 'Continua - Scelta di un metodo di pagamento',
      wait: 'Aspettativa',
      confirmation: 'Conferma di pagamento',
      thankYou: 'Grazie per il pagamento',
      error: 'Errore di pagina',
      errorNotFound: 'Pagina non trovata',
      secureBack: 'Caricamento pagina',
      maintenance: 'Sito in manutenzione',
      regulationsApproval: 'Regolamento e Informativa sulla privacy',
      toTransferData: 'dettagli del bonifico',
      sessionTimeout: 'Pagina dopo la fine della sessione',
      paywayError: 'Canale di pagamento non disponibile'
    }
  },
  header: {
    availabilityAdjust: {
      increaseFontSize: 'Aumenta le dimensioni dei caratteri',
      decreaseFontSize: 'Diminuisci le dimensioni dei caratteri',
      changeContrast: 'Modifica il contrasto',
      changeLanguage: 'Cambia la lingua del sito',
      skipToContent: 'Salta al contenuto',
      skipToContentLabel: 'Passa al contenuto principale',
      logoTitle: 'Logo del sito'
    },
    sessionTimer: {
      timeOut: 'La sessione è scaduta',
      sessionEnd: 'La sessione finisce tra:',
      label: 'Prolunga la durata della sessione',
      button: 'Prolunga'
    }
  },
  footer: {
    text1: 'Hai le ulteriori domande relative a pagamenti online o altri servizi? <a title="Si apre in una nuova finestra" href="https://pomoc.autopay.pl/?pk_campaign=paywall&pk_kwd=start&pk_medium=hyperlink" target="_blank" rel="noreferrer">Vai alla pagina della Guida</a>',
    text2: {
      part1: 'Il titolare del trattamento dei dati personali è Autopay S.A. ',
      part2: 'ul. Powstańców Warszawy 6, 81-718 Sopot. La fornitura dei dati personali è facoltativa, ma necessaria per la realizzazione della segnalazione inviata. La base giuridica, le finalità, il periodo di trattamento dei dati personali ed i diritti dell\'Utente, nonché le altre informazioni importanti riguardanti le regole di trattamento dei dati personali sono indicati in dettaglio nella <a href="{privacyPolicyUrl}" target="_blank" title="Vai alla pagina con il documento Privacy Policy (128 KB, PDF)" rel="noreferrer">Privacy policy di Autopay S.A.</a>'
    },
    linkShowMoreTitle: 'Visualizza ulteriori informazioni',
    linkShowMoreText: 'Leggi di più',
    linkShowLessTitle: 'Nascondi ulteriori informazioni',
    linkShowLessText: 'Leggi meno'
  },
  paywall: {
    header: 'Scegli la modalità di pagamento',
    info: {
      paywayNotAvailable: 'Siamo spiacenti ma la modalità di pagamento selezionata non è disponibile in questo momento sul tuo dispositivo.',
      applePayNotAvailable: 'Siamo spiacenti ma il pagamento con Apple Pay non è disponibile in questo momento sul tuo dispositivo.',
      googlePayNotAvailable: 'Siamo spiacenti ma il pagamento con Google Pay non è disponibile in questo momento sul tuo dispositivo.'
    },
    paywayGroup: {
      inactiveMessage: 'Il pagamento non è al momento disponibile',
      notice: {
        novelty: 'NOVITÀ',
        promotion: 'OFFERTA SPECIALE',
        recommended: 'CONSIGLIATO',
        mastercard: 'IN MODO RAPIDO E SICURO',
        blik: 'BLIKOMANIA - Registrati e vinci diamanti!',
        visaMobile: 'Paga in modo fluido e sicuro'
      },
      bank: {
        many: 'banche',
        one: 'banca',
        two: 'banche'
      },
      wallet: {
        many: 'portafogli',
        one: 'portafoglio',
        two: 'portfele'
      },
      otp: {
        many: 'modi',
        one: 'modo',
        two: 'modi'
      },
      blikPayLater: {
        info: 'Scopri di più'
      }
    },
    paywayList: {
      label: 'Gruppo delle modalità di pagamento',
      linkSelectOther: 'Scegli un\'altra'
    },
    paywaySingle: {
      accountAtAnotherBank: 'Ho un conto presso un\'altra banca'
    },
    paywaySwitch: {
      methodShortSingle: 'Una volta',
      methodLongSingle: 'Pagamenti una tantum',
      methodShortAuto: 'Ciclico',
      methodLongAuto: 'Pagamenti ricorrenti'
    },
    form: {
      payway: {
        label: 'Scegli la modalità di pagamento'
      },
      card: {
        label: 'Inserisci i dati della carta',
        iframe: {
          title: 'Modulo con dati della carta di credito da compilare'
        }
      },
      visaMobile: {
        iframe: {
          title: 'Modulo con numero di telefono da compilare'
        },
        status: {
          pending: {
            statusText: 'In attesa di conferma',
            header: 'Conferma il pagamento nell\'app',
            descriptionText1: 'Il pagamento può essere confermato nell\'app Visa Mobile o nell\'app della banca con il servizio Visa Mobile attivo',
            descriptionText2: 'Dopo aver confermato la transazione nell\'app, aspetta il risultato del pagamento',
            descriptionText3: 'Annullamento della transazione in corso',
            cancelButton: 'Annulla il pagamento'
          },
          success: {
            statusText: 'Grazie per il tuo acquisto!',
            descriptionText: 'Pagamento concluso con successo'
          },
          error: {
            statusText: 'Assicurati di avere fondi sufficienti sulla carta di pagamento utilizzata per il pagamento Visa Mobile.',
            descriptionText: 'Pagamento rifiutato'
          }
        },
        apps: 'App che gestiscono i pagamenti Visa Mobile',
        help: 'Hai bisogno d\'aiuto?',
        info: 'Scopri di più',
        secure: 'Pagamento sicuro grazie alla crittografia SSL',
        modal2: {
          header: 'Per confermare il pagamento aprire l\'app Visa Mobile o l\'app della banca in cui hai il servizio Visa Mobile attivo',
          listText1: 'hai l\'app Visa Mobile installata o il servizio Visa Mobile attivo nell\'app mobile della tua banca',
          listText2: 'hai attivato le notifiche nell\'app Visa Mobile o nell\'app della banca con il servizio Visa Mobile attivo',
          listText3: 'il numero che hai fornito è corretto: <strong class="no-wrap">+{prefix} {phone}</strong>',
          listText4: 'Assicurarsi anche che ...'
        }
      },
      click2pay: {
        loaderText: 'Szukamy Twojego adresu e-mail<br>w <strong>Click to Pay</strong>',
        saveCard: {
          text1: 'Zapisz swoją kartę',
          text2: 'Twoje dane zostaną bezpiecznie zapisane w <strong>Mastercard Click to Pay</strong>',
          link: 'Dowiedz się więcej'
        },
        email: {
          labelForClick2Pay: 'Adres e-mail do powiadomień o płatności'
        },
        cardSelect: {
          selected: 'Wybrana',
          select: 'Wybierz',
          payOther: 'Wpisz dane karty',
          back: 'Powrót'
        },
        cardSelected: {
          header: 'Użyj karty zapisanej w Click to Pay',
          date: 'ważna do',
          buttonChange: 'Zmień'
        },
        codeForm: {
          description: 'Znaleźliśmy Twój adres e-mail w Click to Pay.',
          codeSentEmail: 'Wpisz jednorazowy kod, który wysłaliśmy na adres {email} w celu zalogowania się na konto.',
          codeSentPhone: 'Wpisz jednorazowy kod SMS, który wysłaliśmy na nr {phone} w celu zalogowania się na konto.',
          codeLabel: 'Podaj kod',
          codePlaceholder: 'podaj kod',
          codeLabelFlow: 'Kod',
          codeSendLabel: 'Wyślij nowy kod',
          codeSendAriaLabel: 'przez',
          codeSendAriaLabel2: 'lub',
          buttonSendNewSms: 'SMS',
          buttonSendNewEmail: 'e-mail',
          trustedLabel: 'Dodaj to urządzenie do zaufanych<br><span>Następnym razem rozpoznamy to urządzenie, a Ty zapłacisz bez logowania</span>',
          buttonSubmit: 'Dalej',
          buttonChangeCard: 'Wprowadź dane karty'
        },
        info: {
          description: 'Płatność kartą jest zaszyfrowana i bezpieczna. Transakcja zostanie autoryzowana za pomocą 3DSecure na stronie banku.'
        },
        loginForm: {
          emailLabel: 'Podaj adres e-mail, aby zalogować się do Click to Pay',
          emailPlaceholder: 'adres e-mail',
          emailLabelFlow: 'E-mail',
          buttonSubmit: 'Zaloguj się',
          buttonBack: 'Powrót'
        },
        newUserForm: {
          header: 'Zapisz kartę w Click to Pay',
          description: 'Potrzebujemy jeszcze kilku informacji, by zapisać Twoją kartę.',
          emailLabel: 'Podaj adres e-mail',
          emailPlaceholder: 'adres e-mail',
          emailLabelFlow: 'E-mail',
          countryLabel: 'Kraj',
          firstnameLabel: 'Imię',
          firstnamePlaceholder: 'imię',
          firstnameLabelFlow: 'Imię',
          lastnameLabel: 'Nazwisko',
          lastnamePlaceholder: 'nazwisko',
          lastnameLabelFlow: 'Nazwisko',
          phoneLabel: 'Numer telefonu',
          phonePlaceholder: 'numer telefonu',
          phoneLabelFlow: 'Telefon',
          countryCodePlaceholder: 'kod',
          countryCodeLabelFlow: 'Kod',
          trustedLabel: 'Dodaj to urządzenie do zaufanych<br><span>Następnym razem rozpoznamy to urządzenie, a Ty zapłacisz bez logowania</span>',
          termsLabel: 'Kontynuując, akceptujesz <a href="https://www.mastercard.com/global/click-to-pay/country-listing/terms.html" target="_blank">Warunki korzystania ze strony</a> i&nbsp;rozumiesz, że Twoje dane będą przetwarzane zgodnie z <a href="https://www.mastercard.com/global/click-to-pay/country-listing/privacy.html" target="_blank">Polityką prywatności</a> Mastercard.',
          c2pInfo: 'Click to Pay użyje tych informacji, aby Cię zweryfikować i wysyłać na ten numer kody weryfikacyjne. Mogą zostać naliczone opłaty za wiadomości/transmisję danych.',
          buttonSubmit: 'Zapisz i zapłać',
          buttonBack: 'Zapłać bez zapisywania karty'
        },
        profile: {
          header: 'Zaloguj się do Click to Pay',
          buttonChangeEmail: 'Zmień adres e-mail'
        },
        modal: {
          title: 'Click to Pay - informacje',
          header: 'Płać szybko i bezpiecznie, bez przepisywania danych karty w nowym standardzie Click to Pay',
          text1: 'Podczas płatności kartą szukaj logo <img src="/web/img/click2pay/Click_to_Pay.svg" style="height: 14px" alt="Click to Pay Logo"> i płać kartą płatniczą szybko i wygodnie.',
          text2: 'Click To Pay to nowy standard płatności kartą wspierany przez Visa i Mastercard.',
          text3: 'Kartą na koncie Click to Pay zapłacisz w sklepach w Polsce i za granicą, bez przepisywania danych z karty.',
          text4: 'Dane zapisywane są bezpiecznie w Visa lub Mastercard. Wszystkie informacje są w pełni szyfrowane.',
          text5: 'Podczas kolejnych płatności Click to Pay rozpozna Twój adres e-mail, dzięki czemu od razu zapłacisz zapisanymi kartami.',
          text6: 'Płatności potwierdzasz dodatkowo w swoim banku, co gwarantuje pełne bezpieczeństwo.',
          processError: 'W trakcie wykonywania płatności Click to Pay wystąpił błąd. Wykonaj standardową płatność kartą.'
        }
      },
      email: {
        label: 'Inserisci l\'indirizzo e-mail',
        flowLabel: 'E-mail',
        tip: 'Abbiamo bisogno del tuo indirizzo e-mail per poter inviarti i messaggi relativi allo stato del pagamento.'
      },
      terms: {
        header: 'Consensi e dichiarazioni',
        selectAll: 'Seleziona tutto',
        fieldNotRequired: 'Campo non obbligatorio',
        partnerMarketingTextLess: 'Vuoi ricevere da noi le informazioni via e-mail, SMS o al telefono riguardanti le migliori offerte, promozioni, concorsi e altri eventi interessanti organizzati da noi o dai nostri Partner?',
        partnerMarketingTextMore: 'Promettiamo di non inviare lo spam! Se non vorrai ricevere più i nostri messaggi puoi revocare il consenso in ogni momento.',
        tradeMarketingTextLess: 'Vuoi che trasmettiamo il tuo indirizzo e-mail o il recapito telefonico ai nostri Partner di fiducia per presentarti informazioni interessanti e offerte?',
        tradeMarketingTextMore: 'Ti assicuriamo che il gruppo dei nostri Partner include le aziende di fiducia che, come noi, non vogliono inviare lo spam e potrai revocare il consenso in ogni momento.'
      },
      termsLabelDefault: 'Ho letto e approvo <a href="{regulationUrl}" class="form-text-link" target="_blank" title="Scarica il file con il regolamento del servizio" rel="noreferrer">Regolamento</a> della prestazione dei servizi di pagamento e <a href="{privacyPolicyUrl}" class="form-text-link" target="_blank" title="Scarica il file con la privacy policy di Autopay S.A.">Politica sulla riservatezza</a>. Voglio la realizzazione immediata del servizio e sono consapevole che non riceverò il rimborso dei costi sostenuti a titolo dei servizi realizzati sulla mia richiesta fino al momento del recesso dal contratto.',
      termsReccuring: 'Se vuoi eseguire il pagamento, approva <a href="{regulationUrl}" class="form-text-link" target="_blank" title="Scarica il file con il regolamento del servizio" rel="noreferrer">Regolamento</a> e <a href="{privacyPolicyUrl}" class="form-text-link" target="_blank" title="Scarica il file con la privacy policy di Autopay S.A.">Politica sulla riservatezza</a> Autopay S.A., consenso all\'esecuzione immediata del servizio di pagamento e consenso all\'addebito sulla mia carta di pagamento.',
      termsReccuringMore1: 'Acconsento alla realizzazione immediata del servizio e sono consapevole che non riceverò il rimborso dei costi sostenuti a titolo dei servizi realizzati sulla mia richiesta fino al momento del recesso dal contratto.',
      termsReccuringMore2: 'Acconsento all\'addebito ciclico da parte di Autopay S.A. sulla mia carta di pagamento al fine di realizzare la transazione a favore di {receiverName}. Le regole dell\'addebito ciclico della carta sono <a href="{regulationUrl}" class="form-text-link" target="_blank" title="Scarica il file con il regolamento del servizio" rel="noreferrer">Regolamento</a> di prestazione dei servizi di pagamento. Dichiaro di essere a conoscenza di termini, frequenza e regole di definizione dell\'importo addebitato sulla mia carta di pagamento, nonché delle modalità di revoca del presente consenso, accordate da me con {receiverName}. In caso di domande relative all\'addebito ciclico sulla carta di pagamento devo contattare {receiverName}.',
      mwfClauseLess: 'Se si desidera effettuare il pagamento, approvare il <a href="{regulationUrl}" target="_blank" title="Regolamento" rel="noreferrer">Regolamento</a> e <a href="{privacyPolicyUrl}" target="_blank" title="privacy policy di Autopay S.A">la privacy policy di Autopay S.A.</a>, il consenso all\'avvio della transazione presso la tua banca tramite Autopay S.A. e alla fornitura dei dettagli della transazione al destinatario, nonché l\'ordine di realizzazione immediata del servizio di pagamento.',
      mwfClauseMore: 'Acconsento alla realizzazione immediata del servizio e sono consapevole che non riceverò il rimborso dei costi sostenuti in caso di recesso dal contratto.',
      mwfClauseARIA: 'Se vuoi effettuare un pagamento, accetta il Regolamento e l\'informativa sulla privacy di Autopay S.A., acconsenti ad avviare transazioni presso la tua banca tramite Autopay S.A. e consegnando i dettagli della transazione al beneficiario e richiedendo l\'esecuzione immediata del servizio di pagamento. Desidero che il servizio venga erogato immediatamente e so che non riceverò alcun rimborso in caso di recesso dal contratto. <a href="{regulationUrl}" class="form-text-link" target="_blank" title="" rel="noreferrer" tabindex="-1">Scarica il file con i termini del servizio</a> <a href="{privacyPolicyUrl}" class="form-text-link" target="_blank" title="" tabindex="-1">Scarica il file dall\'informativa sulla privacy di Autopay S.A.</a>',
      paymentInfo: 'L\'ordine di pagamento viene presentato tramite Autopay S.A. con sede a  Sopot e  sarà realizzato secondo le condizioni stabilite dalla tua banca.Dopo  aver scelto  la banca autorizzi il pagamento.',
      changePaywayLink1: 'Puoi ancora',
      changePaywayLink2: 'cambiare la modalità di pagamento',
      linkMore: 'ESPANDI',
      linkShowMoreTitle: 'Visualizza informazioni aggiuntive',
      linkLess: 'NASCONDI',
      linkShowLessTitle: 'Nascondi informazioni aggiuntive',
      submitButton: {
        visaMobile: 'Pagare con',
        fastTransfer: 'Genera gli estremi del bonifico',
        mwf: 'Approvo e pago',
        pis: 'Approvo e avvio il pagamento',
        auto: 'Ordine pagamento ricorrente',
        autoPayment: 'Pagamento dell\'ordine',
        other: 'Paga',
        orange: 'Confermare',
        c2pNext: 'Dalej',
        c2pAccept: 'Akceptuję',
        c2pSaveAndPay: 'Zapisz i zapłać'
      }
    },
    frData: {
      pageTitle: 'Dati dettagliati per il trasferimento',
      header: 'Ordina il bonifico con dati indicati',
      nrbLabel: 'Conto del Destinatario',
      nameLabel: 'Nome del Destinatario',
      addressLabel: 'Indirizzo del Destinatario',
      titleLabel: 'Causale del bonifico',
      amountLabel: 'Importo del bonifico',
      timeLabel: 'Esegui la transazione entro il',
      linkCopy: 'Copia',
      linkGetPdf: 'Scarica il file PDF con dati',
      linkGetPdfTitle: 'Si apre in una nuova finestra',
      linkGoToBank: 'Esegui il log-in alla banca',
      info: 'Accedi al tuo conto bancario. Copia i dettagli nell’ordine di bonifico. È importante che i dati siano conformi, non modificare l’importo, la valuta, la causale, etc.'
    },
    processing: {
      pageTitle: 'Pagina in attesa per l\'elaborazione della transazione',
      loaderAnimation: 'Animazione visualizzata durante l\'attesa',
      header: 'La pagina del pagamento viene preparata.',
      description: 'Ancora un attimo....',
      info: {
        header: 'Nota bene!',
        description: 'Non chiudere la pagina dopo la fine della transazione, altrimenti i tuoi soldi non saranno inviati al destinatario.'
      },
      loaderText: 'Invio dati in corso.<br>Ci vogliono al massimo 30 secondi.'
    }
  },
  thankYou: {
    pageTitle: 'Informazioni sull\'esito della transazione',
    form: {
      emailLabel: 'E-mail',
      buttonSubmit: 'Torna al sito del partner'
    },
    negative: {
      header: 'Il pagamento non è stato realizzato a causa della mancata autorizzazione alla transazione.',
      description: 'Se vuoi ancora approfittare dell\'offerta di un dato venditore o fornitore di servizi ritorna al suo sito web, scegli il prodotto o il servizio e provvedi al pagamento con la modalità preferita.'
    },
    pending: {
      header: 'Grazie per aver ordinato il pagamento tramite il gateway di pagamento.',
      description: 'È in corso la verifica dello stato della transazione. Quando il processo finisce riceverai l\'informazione sullo stato del pagamento sulla tua casella postale.'
    },
    positive: {
      header: 'Grazie, il tuo pagamento è stato realizzato.',
      description: 'Riceverai la conferma al tuo indirizzo di posta elettronica.'
    }
  },
  regulationsApproval: {
    header: 'Accetta la transazione'
  },
  wait: {
    pageTitle: 'Pagina in attesa'
  },
  sessionTimeout: {
    pageTitle: 'Pagina dopo la fine della sessione',
    header: 'È scaduto il tempo per il pagamento',
    description: 'Puoi sempre ritornare al sito del partner, ripetere l\'ordine o utilizzare il servizio e pagare con la modalità preferita.'
  },
  paywayError: {
    pageTitle: 'Il canale di pagamento selezionato non è disponibile',
    header: 'Purtroppo, il metodo di pagamento selezionato non è al momento disponibile.',
    description: 'Torna alla pagina del partner e riprova più tardi.'
  },
  loader: {
    defaultAnimation: 'Animazione visualizzata durante il caricamento della pagina',
    slider: 'Dispositivo di scorrimento con testi modificabili',
    text1: 'In Autopay ci impegniamo per la neutralità carbonica. Ci sostieni utilizzando i nostri servizi.',
    text2: 'In Autopay andiamo al lavoro in bicicletta, limitando l\'emissione di gas serra nell\'atmosfera. Ci sostieni utilizzando i nostri servizi.',
    text3: 'In Autopay ci alimenta l\'energia verde. Ci sostieni utilizzando i nostri servizi.',
    text4: 'In Autopay limitiamo il consumo di carta. Ci sostieni utilizzando i nostri servizi.'
  },
  orderDetails: {
    header: 'Dettagli',
    headerAriaLabel: 'Dettagli di transazione',
    amountLabel: 'Importo dovuto',
    receiverLabel: 'Destinatario',
    orderLabel: 'Numero dell\'ordine',
    amountOryginalCurrencyLabel: 'Importo in {currency}',
    currencyRateLabel: 'Tasso di cambio',
    feeLabel: 'Spese di realizzazione della transazione',
    customerNumberLabel: 'Numero del Cliente',
    invoiceNumberLabel: 'Numero della fattura',
    accountNumberLabel: 'Numero del conto',
    paywayLabel: 'Metodo di pagamento',
    paywayName: 'Bonifico bancario online',
    linkBack: 'Torna al negozio',
    linkBackTitle: 'Esci dalla transazione e torna al negozio',
    linkMore: 'espandi',
    linkLess: 'nascondi',
    toggleBtnUnfoldAriaLabel: 'Espandi dettagli dell\'ordine',
    toggleBtnFoldAriaLabel: 'Nascondi dettagli dell\'ordine'
  },
  error: {
    pageTitle: 'Pagina di errore',
    linkBackUrlSet: 'Torna al sito del partner',
    linkBackUrlNotSet: 'Vai alla pagina di assistenza',
    sessionTimeout: {
      title: 'È scaduto il tempo per il pagamento',
      message: 'Puoi sempre ritornare al sito del partner, ripetere l\'ordine o utilizzare il servizio e pagare con la modalità preferita.'
    },
    notFoundError: {
      pageTitle: 'Errore pagina 404 - pagina non trovata',
      title: 'Sei andato troppo lontano e non siamo in grado di ritrovare la pagina che vuoi visitare.',
      message: 'Possibili cause: la pagina non esiste o è stata cancellata, sono in corso le operazioni di manutenzione, l\'indirizzo non è corretto, si è verificato un errore tecnico.'
    },
    generalError: {
      title: 'Siamo spiacenti',
      message: 'In questo momento non possiamo realizzare il tuo bonifico.'
    },
    errFieldNotFound: {
      title: 'Siamo spiacenti',
      message: ''
    },
    errBadClientSource: {
      title: 'Siamo spiacenti',
      message: ''
    },
    nrParametersError: {
      title: 'Siamo spiacenti',
      message: 'In questo momento non possiamo realizzare il tuo bonifico.'
    },
    transactionOutdated: {
      title: 'È scaduto il tempo per il pagamento.',
      message: 'Ordina nuovamente il pagamento.'
    },
    linkValidityTimeOutdated: {
      title: 'È scaduto il tempo per il pagamento.',
      message: 'Ordina nuovamente il pagamento.'
    },
    transactionValidityTimeOutdated: {
      title: 'È scaduto il tempo per il pagamento.',
      message: 'Ordina nuovamente il pagamento.'
    },
    multiplyTransaction: {
      title: 'Siamo spiacenti',
      message: 'La transazione esiste già ed è in attesa del pagamento.'
    },
    transactionCanceled: {
      title: 'Siamo spiacenti',
      message1: 'La transazione è stata annullata.',
      message2: 'Ordina nuovamente il pagamento.'
    },
    multiplyPaidTransaction: {
      title: 'Siamo spiacenti',
      message: 'La transazione è stata già pagata.'
    },
    transactionReject: {
      title: 'Siamo spiacenti',
      message1: 'Ops ... qualcosa è andato storto.',
      message2: 'La riparazione del gateway è in corso...'
    },
    bankDisabled: {
      title: 'Siamo spiacenti',
      message: 'La banca selezionata non è disponibile in questo momento.'
    },
    bankTemporaryMaintenance: {
      title: 'Siamo spiacenti',
      message: 'In questo momento non possiamo realizzare il tuo bonifico.'
    },
    insufficientStartAmount: {
      title: 'Siamo spiacenti',
      message: 'Il limite dell\'importo del bonifico è stato superato.'
    },
    startAmountOutOfRange: {
      title: 'Siamo spiacenti',
      message: 'Il limite dell\'importo del bonifico è stato superato.'
    },
    nonAccountedLimitExceeded: {
      title: 'Siamo spiacenti',
      message: 'Il pagamento non può essere realizzato perché il venditore ha raggiunto il limite mensile delle vendite.'
    }
  },
  confirmation: {
    pageTitle: 'Informazioni sull\'avanzamento del pagamento',
    linkBack: 'Torno al sito',
    linkBackTimeout: 'Torna al sito web del partner tra',
    incorrectAmount: {
      header: 'Importo errato',
      description: 'Non abbiamo trasferito il pagamento al destinatario perché l\'importo non era corretto.',
      description2: 'Fai clic sul pulsante per tornare alla pagina e rifarla. Ricordati di non modificare nessun dato.'
    },
    multiplePaid: {
      header: 'Doppio deposito',
      description: 'Il destinatario ha ricevuto il pagamento due volte. Non preoccuparti: riceverai il rimborso.',
      description2: 'Contatta il destinatario e forniscigli la conferma del pagamento.'
    },
    negative: {
      header: 'Fine del tempo',
      description: 'Non abbiamo trasferito il pagamento al destinatario perché il tempo di conferma è scaduto.',
      description2: 'Fai clic sul pulsante per tornare alla pagina e rifarla.'
    },
    notFound: {
      header: 'Nessun pagamento',
      description: 'Si è verificato un errore durante l\'elaborazione del pagamento. Ma nulla è perduto.',
      description2: 'Fai clic sul pulsante per tornare alla pagina e rifarla.'
    },
    outdated: {
      header: 'Fine del tempo',
      description: 'Non abbiamo trasferito il pagamento al destinatario.',
      description2: 'Fai clic sul pulsante per tornare alla pagina e rifarla.'
    },
    outdatedPaidNotRefunded: {
      header: 'Fine del tempo',
      description: 'Non abbiamo trasferito il pagamento al destinatario. Se il denaro è stato prelevato dal tuo conto, non preoccuparti, riceverai il rimborso entro pochi giorni.',
      description2: 'Fai clic sul pulsante per tornare alla pagina e rifarla.'
    },
    outdatedPaidRefunded: {
      header: 'Fine del tempo',
      description: 'Non abbiamo trasferito il pagamento al destinatario. Se il denaro è stato prelevato dal tuo conto, non preoccuparti, lo abbiamo già rimborsato.',
      description2: 'Fai clic sul pulsante per tornare alla pagina e rifarla.'
    },
    pending: {
      header: 'In corso',
      description: 'Stiamo elaborando il pagamento. Ti avviseremo quando raggiungerà il destinatario.'
    },
    positive: {
      header: 'Pagato',
      visaVideo: 'Video pubblicitario riprodotto dopo una transazione andata a buon fine',
      description: 'Abbiamo trasferito i tuoi fondi al destinatario.'
    }
  },
  messages: {
    accessForbidden: 'Accesso negato',
    internalServerError: 'Si è verificato un errore inaspettato. Riprova più tardi.',
    validators: {
      required: 'Campo obbligatorio',
      emailRequired: 'L\'indirizzo e-mail è obbligatorio.',
      emailNotValid: 'Il tuo indirizzo e-mail contiene un errore. Inserisci l\'indirizzo e-mail corretto',
      emailNotRecognized: 'Podany e-mail nie jest zarejestrowany',
      emailIdentityLookup: 'Nie udało się zweryfikować adresu e-mail',
      emailLocked: 'Podany e-mail jest tymczasowo zablokowany w systemie Mastercard',
      paywayRequired: 'Scegli la modalità di pagamento',
      creditCardRequired: 'Completa tutti i campi',
      phoneRequired: 'Si prega di inserire un numero di telefono valido',
      regulationRequired: 'Accetta il regolamento',
      requiredConsent: 'Il consenso è obbligatorio',
      codeNotValid: 'Podany kod nie jest prawidłowy',
      codeAccountLocked: 'Konto zostało tymczasowo zablokowane w systemie Mastercard'
    },
    validatorsAria: {
      required: 'Attenzione. Il campo del modulo non è stato compilato. Campo obbligatorio.',
      emailRequired: 'Attenzione. Il campo e-mail non è stato compilato. Si prega di inserire un indirizzo email valido.',
      emailNotValid: 'Attenzione. Il tuo indirizzo e-mail contiene un errore. Si prega di inserire un indirizzo email valido.',
      emailNotRecognized: 'Uwaga. Podany e-mail nie jest zarejestrowany.',
      emailIdentityLookup: 'Uwaga. Nie udało się zweryfikować podanego adresu e-mail',
      emailLocked: 'Uwaga. Podany e-mail jest tymczasowo zablokowany w systemie Mastercard.',
      paywayRequired: 'Attenzione. Il pagamento non è stato selezionato. Scegli il metodo di pagamento.',
      creditCardRequired: 'Attenzione. Completa tutti i campi con i dati della carta di credito.',
      phoneRequired: 'Attenzione. Errore nel campo con numero di telefono. Inserisci il numero di telefono corretto.',
      codeNotValid: 'Uwaga. Podany kod nie jest prawidłowy.',
      codeAccountLocked: 'Uwaga. Konto zostało tymczasowo zablokowane w systemie Mastercard.'
    },
    success: {
      copy: 'Copiato!',
      codeSent: 'Kod został wysłany!'
    },
    errors: {
      getPdf: 'Non siamo riusciti a scaricare il file PDF!',
      copy: 'Non è stato possibile copiare!',
      thankYouUpdate: 'Non è stato possibile salvare l\'indirizzo e-mail!',
      groupInactive: 'Al momento è impossibile effettuare un trasferimento utilizzando il gruppo {groupName}. Seleziona un altro metodo di pagamento.',
      paywayInactive: 'Attualmente per motivi tecnici il bonifico dalla banca prescelta non è possibile. Scegli un\'altra banca o un\'altra modalità di pagamento.',
      googlePayInactive: 'Purtroppo il pagamento Google Pay non è disponibile sul tuo dispositivo.',
      applePayInactive: 'Il pagamento Apple Pay è disponibile agli utilizzatori del browser Safari e dei dispositivi con sistemi operativi iOS.',
      codeNotSent: 'Nie udało się wysłać ponownie kodu!',
      codeCounterExceeded: 'Przekroczono dozwoloną liczbę prób. Wprowadź dane karty ręcznie lub skorzystaj z innej metody płatności.'
    }
  },
  modal: {
    closeButton: 'Chiudi',
    confirmButton: 'Acconsento'
  },
  configuration: {
    paywayGroups: {
      auto: {
        label: 'Pagamento ricorrente',
        description: 'Inserisci i dettagli della carta che utilizzerai per il pagamento. I pagamenti automatici verranno prelevati dalla tua carta in un secondo momento.',
        labelInitWithRefund: 'Pagamento di verifica',
        labelInitWithRefundOrange: 'Aggiungi una carta di pagamento',
        labelInitWithPayment: 'Pagamento con carta',
        descriptionInitWithRefund: 'Inserisci i dati della carta da verificare per i futuri pagamenti. Nella fase successiva, addebiteremo 1 PLN dalla carta fornita per verificare la correttezza dei dati. Dopo la verifica, rimborseremo questo importo.',
        descriptionInitWithPayment: 'Inserisci i dati della carta che sarà utilizzata nella transazione e al contempo verificata per i futuri pagamenti.'
      },
      blik: {
        label: 'BLIK',
        description: 'Paga subito inserendo il codice BLIK senza loggarsi e registrarsi'
      },
      card: {
        label: 'Scegli la modalità di pagamento',
        description: 'Paga con la tua carta di pagamento'
      },
      pbl: {
        label: 'Bonifico online',
        description: 'Seleziona la banca da cui desideri effettuare il pagamento',
        descriptionVerify: 'Verifica dell\'identità in linea',
        descriptionServiceVerify: 'Seleziona la banca che ti permetterà di verificare il servizio on-line'
      },
      visaMobile: {
        label: 'Visa Mobile',
        description: 'Pagamento tramite cellulare Visa'
      },
      wallet: {
        label: 'Portafoglio virtuale',
        description: 'Paga senza loggarsi ai servizi bancari online'
      },
      installments: {
        label: 'Paga a rate',
        description: 'Paga i tuoi acquisti con comode rate'
      },
      alior: {
        descriptionInfo1: 'Paga i tuoi acquisti utilizzando comode rate',
        descriptionInfo2: '(controlla i dettagli)',
        aliorModal: {
          text1: 'A seconda delle impostazioni del sito web su cui effettui l\'acquisto, puoi acquistare i prodotti a rate nelle seguenti varianti:',
          text2: '<ul><li>10 rate 0%</li><li>20 rate 0%</li><li>da 3 a 48 rate con costo mensile dell 1% (secondo l\'esempio rappresentativo fornito per una determinata transazione)</li></ul>'
      }
    },
      otp: {
        label: 'Paga più tardi',
        description: 'Acquista ora paga dopo',
        descriptionInfo1Mwf: 'Paga più tardi la fattura: fino a 45 giorni consecutivi o in più rate uguali',
        descriptionInfo1Ecommerce: 'Paga più tardi - fino a 45 giorni consecutivi o in più rate uguali',
        descriptionInfo2Mwf: 'Informazioni sull\'intermediario del credito e sul costo',
        descriptionInfo2Ecommerce: 'Informazioni sui costi',
        labelPayka: 'Payka',
        descriptionPayka: 'Paga più tardi la fattura: in una tranche fino a 30 giorni o in 4 rate uguali <a href="" id="paykaShowInModal">Informazioni sull\'intermediario del credito e sul costo</a>',
        descriptionBlikPayLater: 'Il servizio è disponibile presso Millenium Bank e VeloBank',
        paykaModalMwf: {
          header1: 'Informazioni sul mediatore creditizio',
          text1: 'Autopay S.A., con sede a Sopot, agisce in qualità di mediatore creditizio di Paytree S.A. con sede a Gdańsk (Prestatore), abilitato a presentare un’offerta di credito ai clienti e reindirizzare al sito web del Prestatore, inclusa una domanda di credito. I dettagli dell’offerta sono consultabili qui: <a href="https://payka.pl/rachunki-domowe" target="_blank">Rachunki domowe - Payka</a>.',
          header2: 'Dati relativi al costo',
          text2: 'Paga più tardi - fino a 45 giorni consecutivi o in più rate uguali. Dati di costo per un esempio rappresentativo: Rimborso entro 45 giorni TAEG 0%; Rimborso rata TAEG 86,83%. Dettagli dell\'offerta su: <a href="https://payka.pl" target="_blank">Payka.pl</a>'
        },
        paykaModalEcommerce: {
          header2: 'Dati relativi al costo',
          text2: 'Paga più tardi - fino a 45 giorni consecutivi o in più rate uguali. Dati di costo per un esempio rappresentativo: Rimborso entro 45 giorni TAEG 0%; Rimborso rata TAEG 86,83%. Dettagli dell\'offerta su: <a href="https://payka.pl" target="_blank">Payka.pl</a>'
        },
        blikPayLaterModal: {
          header1: 'Acquista ora e paga entro 30 giorni',
          header2: 'Codice BLIK',
          text2: 'Inserisci il codice BLIK e ti offriamo un limite di acquisto fino a 4.000 PLN',
          header3: 'Acquisti prodotti e li paghi in seguito',
          text3: 'Hai tempo per verificare se tutto è adatto a te',
          header4: 'Paghi entro 30 giorni gratuitamente',
          text4: 'Puoi anche restituire i tuoi acquisti entro il tempo specificato dal negozio'
        }
      },
      companyPayments: {
        label: 'Pagamenti aziendali',
        description: 'Posticipa di 30 giorni i termini di pagamento per gli acquisti aziendali'
      },
      transfer: {
        label: 'Estremi del bonifico',
        description: 'Ordina il bonifico con dati indicati'
      },
      other: {
        label: 'Altro',
        description: ''
      }
    },
    mobileEvents: {
      statusDescription: {
        bankDisabled: 'Siamo spiacenti. In questo momento non possiamo realizzare il tuo bonifico. La banca selezionata non è disponibile in questo momento.',
        blockMultipleTransactions: 'Siamo spiacenti. La transazione esiste già ed è in attesa del pagamento.',
        blockPaidTransactions: 'Siamo spiacenti. La transazione è stata già pagata. Non si può ripetere il pagamento.',
        canceledTransactions: 'La transazione che vuoi pagare è stata annullata e non è disponibile. Si prega di iniziare una nuova transazione.',
        declined: 'Autorizzazione negata.',
        generalError: 'Si è verificato un problema temporaneo con la connessione. In questo momento non possiamo realizzare il tuo bonifico. Riprova più tardi.',
        insufficientStartAmount: 'Siamo spiacenti. Il limite dell\'importo del bonifico è stato superato.',
        internalServerError: 'Ops... si è verificato un errore :(. Cerchiamo di risolvere questo problema. Riprova più tardi.',
        nonAccountedLimitExceeded: 'Il pagamento non può essere realizzato perché il venditore ha raggiunto il limite mensile delle vendite.',
        outdatedError: 'È scaduto il tempo per il pagamento..',
        paid: 'Transazione conclusa con successo.',
        paywayLoaded: 'Il sito del bonifico rapido è stato caricato',
        paywaylistLoaded: 'Il sito con la scelta della modalità di pagamento è stato caricato'
      }
    }
  },
  maintenance: {
    text1: 'Sono in corso le pulizie annuali nel gateway di pagamento Autopay, per cui l\'ordine di pagamento non è attualmente possibile. Ci scusiamo per l\'inconveniente.',
    text2: 'Il gateway sarà di nuovo funzionante già il 16 gennaio alle ore 10:00.',
    text3: 'Grazie per la comprensione.'
  }
}
